export var ASSET_KEY = {
  BACKGROUND: 'BACKGROUND',
  COMING_SOON: 'COMING_SOON',
  NO_MISSIONS: 'NO_MISSIONS',
  NOTIFICATION: 'NOTIFICATION',
  GRAND_PRIZE: 'GRAND_PRIZE',
  GRAND_PRIZE_IMAGE: 'GRAND_PRIZE_IMAGE',
  CARD_BLUE: 'CARD_BLUE',
  CARD_PURPLE: 'CARD_PURPLE',
  CARD_ORANGE: 'CARD_ORANGE',
  CARD_GREEN: 'CARD_GREEN',
  CARD_SHADOW: 'CARD_SHADOW',
  COIN_IMAGE: 'COIN_IMAGE',
  COLLECT_BONUS_IMAGE: 'COLLECT_BONUS_IMAGE',
  FREE_SPINS_IMAGE: 'FREE_SPINS_IMAGE',
  GAME_ICON_IMAGE: 'GAME_ICON_IMAGE',
  LEVEP_UP_IMAGE: 'LEVEP_UP_IMAGE',
  UNLOCK_IMAGE: 'UNLOCK_IMAGE',
  BET_TEXT: 'BET_TEXT',
  COLLECT_BONUS_TEXT: 'COLLECT_BONUS_TEXT',
  FREE_SPINS_TEXT: 'FREE_SPINS_TEXT',
  LEVEP_UP_TEXT: 'LEVEP_UP_TEXT',
  MAX_BET_TEXT: 'MAX_BET_TEXT',
  SPIN_TEXT: 'SPIN_TEXT',
  UNLOCK_TEXT: 'UNLOCK_TEXT',
  WIN_TEXT: 'WIN_TEXT',
  BAR_BASE_BLUE: 'BAR_BASE_BLUE',
  BAR_BASE_PURPLE: 'BAR_BASE_PURPLE',
  BAR_FILL_SIDE: 'BAR_FILL_SIDE',
  BAR_FILL: 'BAR_FILL',
  BAR_SHADOW: 'BAR_SHADOW',
  BAR_COLLECT_BUTTON: 'BAR_COLLECT_BUTTON',
  BAR_LOCKED_ICON: 'BAR_LOCKED_ICON',
  BAR_PLAY_ICON: 'BAR_PLAY_ICON',
  TOP_BAR_ICON: 'TOP_BAR_ICON',
  MISSION_TIMER_ICON: 'MISSION_TIMER_ICON',
  TOP_BAR_BASE: 'TOP_BAR_BASE',
  TOP_BAR_COLLECT: 'TOP_BAR_COLLECT',
  TOP_BAR_FILL: 'TOP_BAR_FILL',
  TOP_BAR_SIDE: 'TOP_BAR_SIDE',
  TOP_BAR_TIMER: 'TOP_BAR_TIMER',
  TABS_BATTLES_1: 'TABS_BATTLES_1',
  TABS_BATTLES_2: 'TABS_BATTLES_2',
  TABS_BATTLES_3: 'TABS_BATTLES_3',
  TABS_EXPERT_1: 'TABS_EXPERT_1',
  TABS_EXPERT_2: 'TABS_EXPERT_2',
  TABS_EXPERT_3: 'TABS_EXPERT_3',
  TABS_EXPLORER_1: 'TABS_EXPLORER_1',
  TABS_EXPLORER_2: 'TABS_EXPLORER_2',
  TABS_EXPLORER_3: 'TABS_EXPLORER_3',
  TUTORIAL_INFO_BUTTON: 'TUTORIAL_INFO_BUTTON',
  TUTORIAL_INFO_SCREEN_STEP_1: 'TUTORIAL_INFO_SCREEN_STEP_1',
  TUTORIAL_INFO_SCREEN_STEP_2: 'TUTORIAL_INFO_SCREEN_STEP_2',
  TUTORIAL_INFO_SCREEN_STEP_3: 'TUTORIAL_INFO_SCREEN_STEP_3',
  TUTORIAL_INFO_SCREEN_ARROW: 'TUTORIAL_INFO_SCREEN_ARROW',
  TUTORIAL_INFO_SCREEN_CLOSE_BTN: 'TUTORIAL_INFO_SCREEN_CLOSE_BTN',
  TUTORIAL_TOOLTIP_STEP_1: 'TUTORIAL_TOOLTIP_STEP_1',
  TUTORIAL_TOOLTIP_STEP_2: 'TUTORIAL_TOOLTIP_STEP_2',
  TUTORIAL_TOOLTIP_STEP_3: 'TUTORIAL_TOOLTIP_STEP_3',
  TUTORIAL_TOOLTIP_STEP_4: 'TUTORIAL_TOOLTIP_STEP_4',
  TUTORIAL_TOOLTIP_STEP_5: 'TUTORIAL_TOOLTIP_STEP_5',
  TUTORIAL_TOOLTIP_STEP_6: 'TUTORIAL_TOOLTIP_STEP_6',
  TUTORIAL_TOOLTIP_STEP_7: 'TUTORIAL_TOOLTIP_STEP_7',
  TUTORIAL_TOOLTIP_STEP_8: 'TUTORIAL_TOOLTIP_STEP_8',
  TUTORIAL_TOOLTIP_STEP_9: 'TUTORIAL_TOOLTIP_STEP_9',
  TUTORIAL_TOOLTIP_STEP_10: 'TUTORIAL_TOOLTIP_STEP_10',
  ANIMATIONS_LIGHT_SWEEP_03: 'ANIMATIONS_LIGHT_SWEEP_03',
  ANIMATIONS_LIGHT_SWEEP_04: 'ANIMATIONS_LIGHT_SWEEP_04',
  ANIMATIONS_LIGHT_SWEEP_05: 'ANIMATIONS_LIGHT_SWEEP_05',
  ANIMATIONS_LIGHT_SWEEP_06: 'ANIMATIONS_LIGHT_SWEEP_06',
  ANIMATIONS_LIGHT_SWEEP_07: 'ANIMATIONS_LIGHT_SWEEP_07',
  ANIMATIONS_LIGHT_SWEEP_08: 'ANIMATIONS_LIGHT_SWEEP_08',
  ANIMATIONS_LIGHT_SWEEP_09: 'ANIMATIONS_LIGHT_SWEEP_09',
  ANIMATIONS_LIGHT_SWEEP_10: 'ANIMATIONS_LIGHT_SWEEP_10',
  ANIMATIONS_LIGHT_SWEEP_11: 'ANIMATIONS_LIGHT_SWEEP_11',
  ANIMATIONS_LIGHT_SWEEP_12: 'ANIMATIONS_LIGHT_SWEEP_12',
  ANIMATIONS_LIGHT_SWEEP_13: 'ANIMATIONS_LIGHT_SWEEP_13',
  ANIMATIONS_LIGHT_SWEEP_14: 'ANIMATIONS_LIGHT_SWEEP_14',
  ANIMATIONS_LIGHT_SWEEP_15: 'ANIMATIONS_LIGHT_SWEEP_15',
  ANIMATIONS_LIGHT_SWEEP_16: 'ANIMATIONS_LIGHT_SWEEP_16',
  ANIMATIONS_LIGHT_SWEEP_17: 'ANIMATIONS_LIGHT_SWEEP_17',
  ANIMATIONS_LIGHT_SWEEP_18: 'ANIMATIONS_LIGHT_SWEEP_18',
  ANIMATIONS_LIGHT_SWEEP_19: 'ANIMATIONS_LIGHT_SWEEP_19',
  ANIMATIONS_LIGHT_SWEEP_20: 'ANIMATIONS_LIGHT_SWEEP_20',
  ANIMATIONS_LIGHT_SWEEP_21: 'ANIMATIONS_LIGHT_SWEEP_21',
  ANIMATIONS_LIGHT_SWEEP_22: 'ANIMATIONS_LIGHT_SWEEP_22',
  ANIMATIONS_LIGHT_SWEEP_23: 'ANIMATIONS_LIGHT_SWEEP_23',
  ANIMATIONS_LIGHT_SWEEP_24: 'ANIMATIONS_LIGHT_SWEEP_24',
  ANIMATIONS_LIGHT_SWEEP_25: 'ANIMATIONS_LIGHT_SWEEP_25',
  ANIMATIONS_LIGHT_SWEEP_26: 'ANIMATIONS_LIGHT_SWEEP_26',
  ANIMATIONS_LIGHT_SWEEP_27: 'ANIMATIONS_LIGHT_SWEEP_27',
  ANIMATIONS_LIGHT_SWEEP_28: 'ANIMATIONS_LIGHT_SWEEP_28',
  ANIMATIONS_LIGHT_SWEEP_29: 'ANIMATIONS_LIGHT_SWEEP_29',
  TOOLTIP_BACKING: 'TOOLTIP_BACKING',
  CARD_BLUE_FRAME: 'CARD_BLUE_FRAME',
  CARD_PURPLE_FRAME: 'CARD_PURPLE_FRAME',
  MODAL_BACKGROUND: 'MODAL_BACKGROUND',
  DAILY_SPIN_BACKGROUND: 'DAILY_SPIN_BACKGROUND',
  DAILY_SPIN_CALENDAR_ICON: 'DAILY_SPIN_CALENDAR_ICON',
  DAILY_SPIN_CARDS_ICON: 'DAILY_SPIN_CARDS_ICON',
  DAILY_SPIN_BOTTOM_BOX: 'DAILY_SPIN_BOTTOM_BOX',
  DAILY_SPIN_WHEEL_ICON: 'DAILY_SPIN_WHEEL_ICON',
  DAILY_SPIN_TEXT_LOGIN: 'DAILY_SPIN_TEXT_LOGIN',
  DAILY_SPIN_TEXT_TOTAL: 'DAILY_SPIN_TEXT_TOTAL',
  DAILY_SPIN_BOTTOM_BACKGROUND: 'DAILY_SPIN_BOTTOM_BACKGROUND',
  DAILY_SPIN_WHEEL: 'DAILY_SPIN_WHEEL',
  DAILY_SPIN_WHEEL_TICKER: 'DAILY_SPIN_WHEEL_TICKER',
  DAILY_SPIN_WHEEL_FRAME: 'DAILY_SPIN_WHEEL_FRAME',
  DAILY_SPIN_WHEEL_SPIN_BTN: 'DAILY_SPIN_WHEEL_SPIN_BTN',
  DAILY_SPIN_WHEEL_BTN_LOGO: 'DAILY_SPIN_WHEEL_BTN_LOGO',
  DAILY_SPIN_PRIZE_STACK: 'DAILY_SPIN_PRIZE_STACK',
  DAILY_SPIN_WHEEL_SKIP_BTN: 'DAILY_SPIN_WHEEL_SKIP_BTN',
  DAILY_SPIN_COLUMN_BACKGROUND: 'DAILY_SPIN_COLUMN_BACKGROUND',
  DAILY_SPIN_STREAK_ROW_DIVIDER: 'DAILY_SPIN_STREAK_ROW_DIVIDER',
  DAILY_SPIN_STREAK_DAY_INDICATOR: 'DAILY_SPIN_STREAK_DAY_INDICATOR',
  DAILY_SPIN_COLUMN_LIGHT_1: 'DAILY_SPIN_COLUMN_LIGHT_1',
  DAILY_SPIN_COLUMN_LIGHT_2: 'DAILY_SPIN_COLUMN_LIGHT_2',
  DAILY_SPIN_COLUMN_LIGHT_3: 'DAILY_SPIN_COLUMN_LIGHT_3',
  DAILY_SPIN_COLUMN_LIGHT_4: 'DAILY_SPIN_COLUMN_LIGHT_4',
  DAILY_SPIN_COLUMN_LIGHT_5: 'DAILY_SPIN_COLUMN_LIGHT_5',
  DAILY_SPIN_COLUMN_LIGHT_6: 'DAILY_SPIN_COLUMN_LIGHT_6',
  DAILY_SPIN_COLUMN_LIGHT_7: 'DAILY_SPIN_COLUMN_LIGHT_7',
  DAILY_SPIN_COLUMN_LIGHT_8: 'DAILY_SPIN_COLUMN_LIGHT_8',
  DAILY_SPIN_COLUMN_LIGHT_9: 'DAILY_SPIN_COLUMN_LIGHT_9',
  DAILY_SPIN_COLUMN_LIGHT_OFF: 'DAILY_SPIN_COLUMN_LIGHT_OFF',
  DAILY_SPIN_COLUMN_RIBBON: 'DAILY_SPIN_COLUMN_RIBBON',
  DAILY_SPIN_COLUMN_RIBBON_HERO: 'DAILY_SPIN_COLUMN_RIBBON_HERO',
  DAILY_SPIN_COLUMN_INFO_ICON: 'DAILY_SPIN_COLUMN_INFO_ICON',
  DAILY_SPIN_COLUMN_INFO_POPUP: 'DAILY_SPIN_COLUMN_INFO_POPUP',
  DAILY_SPIN_MILESTONE_CIRCLE: 'DAILY_SPIN_MILESTONE_CIRCLE',
  DAILY_SPIN_INFO_POPUP: 'DAILY_SPIN_INFO_POPUP',
  EVENTS_BACKGROUND: 'EVENTS_BACKGROUND',
  EVENTS_CARD_BLUE: 'EVENTS_CARD_BLUE',
  EVENTS_CARD_PURPLE: 'EVENTS_CARD_PURPLE',
  EVENTS_CARD_ORANGE: 'EVENTS_CARD_ORANGE',
  EVENTS_CARD_MULTIlPLE_GAMES: 'EVENTS_CARD_MULTIlPLE_GAMES',
  EVENTS_CARD_MULTIlPLE_ACTIVITIES: 'EVENTS_CARD_MULTIlPLE_ACTIVITIES',
  EVENTS_CARD_ALL_GAMES: 'EVENTS_CARD_ALL_GAMES',
  EVENTS_CARD_BOTTOM_BLUE: 'EVENTS_CARD_BOTTOM_BLUE',
  EVENTS_CARD_BOTTOM_PURPLE: 'EVENTS_CARD_BOTTOM_PURPLE',
  EVENTS_CARD_BOTTOM_ORANGE: 'EVENTS_CARD_BOTTOM_ORANGE',
  EVENTS_CARD_BOTTOM_PINK: 'EVENTS_CARD_BOTTOM_PINK',
  EVENTS_CARD_FRAME_GLOW: 'EVENTS_CARD_FRAME_GLOW',
  EVENTS_CARD_FRAME_GLOW_LIVE_OPS: 'EVENTS_CARD_FRAME_GLOW_LIVE_OPS',
  EVENTS_CARD_FRAME_SHADOW: 'EVENTS_CARD_FRAME_SHADOW',
  EVENTS_CARD_IMAGE_LIVE_OPS: 'EVENTS_CARD_IMAGE_LIVE_OPS',
  EVENTS_MULTIPLE_ACTIVITIES_TITLE: 'EVENTS_MULTIPLE_ACTIVITIES_TITLE',
  EVENTS_MULTIPLE_GAMES_TITLE: 'EVENTS_MULTIPLE_GAMES_TITLE',
  EVENTS_ALL_GAMES_TITLE: 'EVENTS_ALL_GAMES_TITLE',
  EVENTS_PODIUM_ACTIVE: 'EVENTS_PODIUM_ACTIVE',
  EVENTS_PODIUM_DISABLED: 'EVENTS_PODIUM_DISABLED',
  EVENTS_PODIUM_LIGHT: 'EVENTS_PODIUM_LIGHT',
  EVENTS_DEFAULT_RIBBON: 'EVENTS_DEFAULT_RIBBON',
  EVENTS_DEFAULT_RIBBON_SHADOW: 'EVENTS_DEFAULT_RIBBON_SHADOW',
  EVENTS_MAIN_RIBBON_TITLE: 'EVENTS_MAIN_RIBBON_TITLE',
  EVENTS_MAIN_RIBBON_CROWN: 'EVENTS_MAIN_RIBBON_CROWN',
  EVENTS_MAIN_RIBBON_TROMBONE: 'EVENTS_MAIN_RIBBON_TROMBONE',
  EVENTS_MAIN_RIBBON_TITLE_SHADOW: 'EVENTS_MAIN_RIBBON_TITLE_SHADOW',
  EVENTS_MAIN_RIBBON_CROWN_SHADOW: 'EVENTS_MAIN_RIBBON_CROWN_SHADOW',
  EVENTS_MAIN_RIBBON_TROMBONE_SHADOW: 'EVENTS_MAIN_RIBBON_TROMBONE_SHADOW',
  EVENTS_DETAIL_CLOSE_BUTTON: 'EVENTS_DETAIL_CLOSE_BUTTON',
  EVENTS_DETAIL_CARD_BLUE: 'EVENTS_DETAIL_CARD_BLUE',
  EVENTS_DETAIL_CARD_PURPLE: 'EVENTS_DETAIL_CARD_PURPLE',
  EVENTS_DETAIL_CARD_ORANGE: 'EVENTS_DETAIL_CARD_ORANGE',
  EVENTS_DETAIL_CARD_PINK: 'EVENTS_DETAIL_CARD_PINK',
  EVENTS_DETAIL_CARD_GREY: 'EVENTS_DETAIL_CARD_GREY',
  EVENTS_DETAIL_COUNTER_BLUE: 'EVENTS_DETAIL_COUNTER_BLUE',
  EVENTS_DETAIL_COUNTER_PURPLE: 'EVENTS_DETAIL_COUNTER_PURPLE',
  EVENTS_DETAIL_COUNTER_ORANGE: 'EVENTS_DETAIL_COUNTER_ORANGE',
  EVENTS_DETAIL_COUNTER_PINK: 'EVENTS_DETAIL_COUNTER_PINK',
  EVENTS_DETAIL_COUNTER_GREY: 'EVENTS_DETAIL_COUNTER_GREY',
  EVENTS_DETAIL_BAR_BLUE: 'EVENTS_DETAIL_BAR_BLUE',
  EVENTS_DETAIL_BAR_PURPLE: 'EVENTS_DETAIL_BAR_PURPLE',
  EVENTS_DETAIL_BAR_ORANGE: 'EVENTS_DETAIL_BAR_ORANGE',
  EVENTS_DETAIL_BAR_PINK: 'EVENTS_DETAIL_BAR_PINK',
  EVENTS_DETAIL_BAR_GREY: 'EVENTS_DETAIL_BAR_GREY',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE: 'EVENTS_DETAIL_FILLABLE_BAR_BASE_ORANGE',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE: 'EVENTS_DETAIL_FILLABLE_BAR_FILL_ORANGE',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE: 'EVENTS_DETAIL_FILLABLE_BAR_CAP_ORANGE',
  EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY: 'EVENTS_DETAIL_FILLABLE_BAR_BASE_GREY',
  EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY: 'EVENTS_DETAIL_FILLABLE_BAR_FILL_GREY',
  EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY: 'EVENTS_DETAIL_FILLABLE_BAR_CAP_GREY',
  EVENTS_DETAIL_CARD_LOGO: 'EVENTS_DETAIL_CARD_LOGO',
  EVENTS_TOOTLIP: 'EVENTS_TOOTLIP',
  EVENTS_NOTIFICATION_BADGE: 'EVENTS_NOTIFICATION_BADGE',
  EVENTS_ICON_ACTIVE: 'EVENTS_ICON_ACTIVE',
  EVENTS_ERROR: 'EVENTS_ERROR',
  EVENTS_COMING_SOON: 'EVENTS_COMING_SOON',
  EVENTS_MUST_UPDATE: 'EVENTS_MUST_UPDATE',
  EVENTS_ICON_ACTIVE_MENU_VERTICAL: 'EVENTS_ICON_ACTIVE_MENU_VERTICAL',
  EVENTS_ERROR_MENU_VERTICAL: 'EVENTS_ERROR_MENU_VERTICAL',
  EVENTS_COMING_SOON_MENU_VERTICAL: 'EVENTS_COMING_SOON_MENU_VERTICAL',
  EVENTS_MUST_UPDATE_MENU_VERTICAL: 'EVENTS_MUST_UPDATE_MENU_VERTICAL',
  EVENTS_TUTORIAL_SLIDE_ONE_ICON: 'EVENTS_TUTORIAL_SLIDE_ONE_ICON',
  EVENTS_TUTORIAL_SLIDE_TWO_ICON: 'EVENTS_TUTORIAL_SLIDE_TWO_ICON',
  EVENTS_TUTORIAL_SLIDE_THREE_ICON: 'EVENTS_TUTORIAL_SLIDE_THREE_ICON',
  EVENTS_TUTORIAL_SLIDE_FOUR_ICON: 'EVENTS_TUTORIAL_SLIDE_FOUR_ICON',
  EVENTS_TUTORIAL_SLIDE_INDICATOR_SELECTED: 'EVENTS_TUTORIAL_SLIDE_INDICATOR_SELECTED',
  EVENTS_TUTORIAL_SLIDE_INDICATOR: 'EVENTS_TUTORIAL_SLIDE_INDICATOR',
  EVENTS_TUTORIAL_INFO_MODAL_BG: 'EVENTS_TUTORIAL_INFO_MODAL_BG',
  EVENTS_TUTORIAL_INFO_ICON: 'EVENTS_TUTORIAL_INFO_ICON',
  EVENTS_TUTORIAL_CLOSE_ICON: 'EVENTS_TUTORIAL_CLOSE_ICON',
  REWARD_CENTER_ONBOARDING: 'REWARD_CENTER_ONBOARDING',
  REWARD_CENTER_BACKGROUND: 'REWARD_CENTER_BACKGROUND',
  REWARD_CENTER_FRAME_CARD: 'REWARD_CENTER_FRAME_CARD',
  REWARD_CENTER_FRAME_CARD_DISABLED: 'REWARD_CENTER_FRAME_CARD_DISABLED',
  REWARD_CENTER_FRAME_CARD_SHADOW: 'REWARD_CENTER_FRAME_CARD_SHADOW',
  REWARD_CENTER_OFFER_FRAME_CARD: 'REWARD_CENTER_OFFER_FRAME_CARD',
  REWARD_CENTER_FRAME_CARD_IS_NEW: 'REWARD_CENTER_FRAME_CARD_IS_NEW',
  REWARD_CENTER_PROFILE_ICON: 'REWARD_CENTER_PROFILE_ICON',
  REWARD_CENTER_RIBBON_EXCLUSIVE: 'REWARD_CENTER_RIBBON_EXCLUSIVE',
  REWARD_CENTER_RIBBON_FEATURED: 'REWARD_CENTER_RIBBON_FEATURED',
  REWARD_CENTER_RIBBON_HOT: 'REWARD_CENTER_RIBBON_HOT',
  REWARD_CENTER_RIBBON_LIMITED: 'REWARD_CENTER_RIBBON_LIMITED',
  REWARD_CENTER_RIBBON_NEW: 'REWARD_CENTER_RIBBON_NEW',
  REWARD_CENTER_RIBBON_SOLD_OUT: 'REWARD_CENTER_RIBBON_SOLD_OUT',
  REWARD_CENTER_IMAGE_COINS: 'REWARD_CENTER_IMAGE_COINS',
  REWARD_CENTER_CLOSED_MENU_BACKGROUND: 'REWARD_CENTER_CLOSED_MENU_BACKGROUND',
  REWARD_CENTER_CLOSED_MENU_TITLE: 'REWARD_CENTER_CLOSED_MENU_TITLE',
  REWARD_CENTER_CLOSED_MENU_CATEGORIES: 'REWARD_CENTER_CLOSED_MENU_CATEGORIES',
  REWARD_CENTER_CLOSED_MENU_ARROW: 'REWARD_CENTER_CLOSED_MENU_ARROW',
  REWARD_CENTER_OPENED_MENU_BACKGROUND: 'REWARD_CENTER_OPENED_MENU_BACKGROUND',
  REWARD_CENTER_OPENED_MENU_TITLE: 'REWARD_CENTER_OPENED_MENU_TITLE',
  REWARD_CENTER_OPENED_MENU_ARROW: 'REWARD_CENTER_OPENED_MENU_ARROW',
  REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT: 'REWARD_CENTER_OPENED_MENU_BUTTON_DEFAULT',
  REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE: 'REWARD_CENTER_OPENED_MENU_BUTTON_ACTIVE',
  REWARD_CENTER_OPENED_MENU_BUTTON_DISABLED: 'REWARD_CENTER_OPENED_MENU_BUTTON_DISABLED',
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS: 'REWARD_CENTER_MENU_TABS_LOYALTY_POINTS',
  REWARD_CENTER_MENU_TABS_LOYALTY_POINTS_SELECTED: 'REWARD_CENTER_MENU_TABS_LOYALTY_POINTS_SELECTED',
  REWARD_CENTER_MENU_TABS_MY_HISTORY: 'REWARD_CENTER_MENU_TABS_MY_HISTORY',
  REWARD_CENTER_MENU_TABS_MY_HISTORY_SELECTED: 'REWARD_CENTER_MENU_TABS_MY_HISTORY_SELECTED',
  REWARD_CENTER_MENU_TABS_MY_PROFILE: 'REWARD_CENTER_MENU_TABS_MY_PROFILE',
  REWARD_CENTER_MENU_TABS_MY_PROFILE_SELECTED: 'REWARD_CENTER_MENU_TABS_MY_PROFILE_SELECTED',
  REWARD_CENTER_MENU_UNDERLINE: 'REWARD_CENTER_MENU_UNDERLINE',
  REWARD_CENTER_MENU_HISTORY_BANNER: 'REWARD_CENTER_MENU_HISTORY_BANNER',
  REWARD_CENTER_PURCHASE_SUCCESS: 'REWARD_CENTER_PURCHASE_SUCCESS',
  REWARD_CENTER_OFFER_DETAILS_ARROW: 'REWARD_CENTER_OFFER_DETAILS_ARROW',
  REWARD_CENTER_PROFILE_FRAME_CARD: 'REWARD_CENTER_PROFILE_FRAME_CARD',
  REWARD_CENTER_PROFILE_LINK_BUTTON: 'REWARD_CENTER_PROFILE_LINK_BUTTON',
  REWARD_CENTER_COINS_ICON: 'REWARD_CENTER_COINS_ICON',
  REWARD_CENTER_COINS_ICON_DISABLED: 'REWARD_CENTER_COINS_ICON_DISABLED',
  REWARD_CENTER_LOYALTY_ICON: 'REWARD_CENTER_LOYALTY_ICON',
  REWARD_CENTER_LOYALTY_ICON_DISABLED: 'REWARD_CENTER_LOYALTY_ICON_DISABLED',
  REWARD_CENTER_OFFER_DETAILS_PURPLE_BAR: 'REWARD_CENTER_OFFER_DETAILS_PURPLE_BAR',
  REWARD_CENTER_ARROW_BACK_BUTTON: 'REWARD_CENTER_ARROW_BACK_BUTTON',
  SOCIAL_FRIENDS_MORE_COINS_TITLE: 'SOCIAL_FRIENDS_MORE_COINS_TITLE',
  SOCIAL_FRIENDS_CHARACTERS_IMAGE: 'SOCIAL_FRIENDS_CHARACTERS_IMAGE',
  SOCIAL_FRIENDS_UNDER_MAINTENANCE: 'SOCIAL_FRIENDS_UNDER_MAINTENANCE',
  SOCIAL_FRIENDS_COMING_SOON: 'SOCIAL_FRIENDS_COMING_SOON',
  SOCIAL_LIMIT_REACHED_IMAGE: 'SOCIAL_LIMIT_REACHED_IMAGE',
  SOCIAL_MESSAGES_DIVIDER: 'SOCIAL_MESSAGES_DIVIDER',
  LOYALTY_ICON: 'LOYALTY_ICON',
  JACKPOT_DISPLAYED_SCREEN: 'JACKPOT_DISPLAYED_SCREEN',
  MESSAGE_INBOX_LOYALTY_PROMO: 'MESSAGE_INBOX_LOYALTY_PROMO',
  SHOP_PURPLE_TRIANGLE_DIVIDER: 'SHOP_PURPLE_TRIANGLE_DIVIDER',
  VIP_ACCESS_STORE_TILE_3_PROMO: 'VIP_ACCESS_STORE_TILE_3_PROMO',
  VIP_ACCESS_STORE_PROMO_FREE: 'VIP_ACCESS_STORE_PROMO_FREE',
  PURPLE_TIMER: 'PURPLE_TIMER',
  COIN_ICON: 'COIN_ICON',
  COIN_GLOW: 'COIN_GLOW',
  SLOT_COINS: 'SLOT_COINS',
  ROUNDEDRECT_DISABLED_WIDE: 'ROUNDEDRECT_DISABLED_WIDE',
  SKIP_BUTTON: 'SKIP_BUTTON',
  SEND_MESSAGE_ICON: 'SEND_MESSAGE_ICON',
  SETTINGS_DIVIDER: 'SETTINGS_DIVIDER',
  FORWARD_ARROW: 'FORWARD_ARROW',
  EXCLAMATION_ICON: 'EXCLAMATION_ICON',
  SELECT_ARROW: 'SELECT_ARROW',
  ICON_SHOW: 'ICON_SHOW',
  STARS_GRAPHIC: 'STARS_GRAPHIC',
  FORM_CONTINUE_BUTTON: 'FORM_CONTINUE_BUTTON',
  TOP_BAR_BACKING: 'TOP_BAR_BACKING',
  CONTINUE_WITH_MAIL: 'CONTINUE_WITH_MAIL',
  APPLE: 'APPLE',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK',
  TIMER_COLLECT: 'TIMER_COLLECT',
  TIMED_BONUS_OPEN: 'TIMED_BONUS_OPEN',
  TIMER_WAITING: 'TIMER_WAITING',
  TIMED_BONUS_CLOSED: 'TIMED_BONUS_CLOSED',
  WATCH: 'WATCH',
  VIP_ENTRY_LOCKED: 'VIP_ENTRY_LOCKED',
  MAIL: 'MAIL',
  WATCH_MENU_VERTICAL: 'WATCH_MENU_VERTICAL',
  MISSIONS_MENU_VERTICAL: 'MISSIONS_MENU_VERTICAL',
  MAIL_MENU_VERTICAL: 'MAIL_MENU_VERTICAL',
  BACKING: 'BACKING',
  REWARDS: 'REWARDS',
  VIP_ROOM: 'VIP_ROOM',
  BOTTOM_BAR_DIVIDER: 'BOTTOM_BAR_DIVIDER',
  SLOTS: 'SLOTS',
  GTP_MISSIONS_ICON_TEXT: 'GTP_MISSIONS_ICON_TEXT',
  MISSIONS: 'MISSIONS',
  WEB_BACKING: 'WEB_BACKING',
  ROUNDED_RECT_DISABLED: 'ROUNDED_RECT_DISABLED',
  CARD_BRONZE: 'CARD_BRONZE',
  CLICK_HERE_BUTTON: 'CLICK_HERE_BUTTON',
  SHOP_PURPLE_CAP: 'SHOP_PURPLE_CAP',
  SESSION_EXPIRED_IMAGE: 'SESSION_EXPIRED_IMAGE',
  COINS_MAIL_ICON: 'COINS_MAIL_ICON',
  ROUNDED_RECT_DANGER: 'ROUNDED_RECT_DANGER',
  CREDIT_CARD_LOGOS: 'CREDIT_CARD_LOGOS',
  PILE_OF_COINS: 'PILE_OF_COINS',
  WARNING_SYMBOL: 'WARNING_SYMBOL',
  VISA: 'VISA',
  MESSAGE_CHAT_BOX: 'MESSAGE_CHAT_BOX',
  MASTERCARD: 'MASTERCARD',
  AMEX: 'AMEX',
  PURCHASE_LIGHT_DIVIDER: 'PURCHASE_LIGHT_DIVIDER',
  ROUNDEDRECT_PRIMARY_WIDE: 'ROUNDEDRECT_PRIMARY_WIDE',
  VIP_ACCESS_STORE_TILE_2_PROMO: 'VIP_ACCESS_STORE_TILE_2_PROMO',
  SHOP_BEST_DEAL: 'SHOP_BEST_DEAL',
  NEWS_ITEM_FRAME_9_SLICE: 'NEWS_ITEM_FRAME_9_SLICE',
  COIN_GRAPHIC_SALE: 'COIN_GRAPHIC_SALE',
  BUY_NOW_BUTTON: 'BUY_NOW_BUTTON',
  DIVIDER: 'DIVIDER',
  TOP_BAR_LOYALTY_ICON: 'TOP_BAR_LOYALTY_ICON',
  IAP_SPLIT_GREEN: 'IAP_SPLIT_GREEN',
  PURPLE_EXP_BAR: 'PURPLE_EXP_BAR',
  IAP_SPLIT_SALE: 'IAP_SPLIT_SALE',
  MENU_HAMBURGER: 'MENU_HAMBURGER',
  HOME_BUTTON: 'HOME_BUTTON',
  EXP_SHIELD: 'EXP_SHIELD',
  TOP_BAR_ASSETS_BACKING: 'TOP_BAR_ASSETS_BACKING',
  IAP_SPLIT_PINK: 'IAP_SPLIT_PINK',
  TOP_BAR_BAR_INSET: 'TOP_BAR_BAR_INSET',
  IAP_BACKING: 'IAP_BACKING',
  TOP_BAR_COIN_ICON: 'TOP_BAR_COIN_ICON',
  IAP_WHOLE_SALE: 'IAP_WHOLE_SALE',
  IAP_WHOLE: 'IAP_WHOLE',
  VIP_TICKET: 'VIP_TICKET',
  CLOCK_ICON: 'CLOCK_ICON',
  SHOP_GOLD_TRIANGLE_DIVIDER: 'SHOP_GOLD_TRIANGLE_DIVIDER',
  ICON_CASINODOLLARS: 'ICON_CASINODOLLARS',
  SHOP_POPULAR: 'SHOP_POPULAR',
  ERROR_BACKGROUND: 'ERROR_BACKGROUND',
  MESSAGE: 'MESSAGE',
  RATE_NOW_BUTTON: 'RATE_NOW_BUTTON',
  RIGHT_ARROW: 'RIGHT_ARROW',
  GOOGLE_BUTTON: 'GOOGLE_BUTTON',
  MEMBER_BUTTON: 'MEMBER_BUTTON',
  LOGIN_GUEST_BUTTON: 'LOGIN_GUEST_BUTTON',
  GUEST_BUTTON: 'GUEST_BUTTON',
  APPLE_BUTTON: 'APPLE_BUTTON',
  FACEBOOK_BUTTON: 'FACEBOOK_BUTTON',
  SPLASH_BG: 'SPLASH_BG',
  START_SCREEN_LOGO: 'START_SCREEN_LOGO',
  SPLASH_BAR_BACKING: 'SPLASH_BAR_BACKING',
  ROUNDED_RECT_QUATERNARY: 'ROUNDED_RECT_QUATERNARY',
  DOT_UNSELECTED: 'DOT_UNSELECTED',
  DOT_SELECTED: 'DOT_SELECTED',
  MAX_BET: 'MAX_BET',
  LEVEL_UP_LIGHT_DIVIDER: 'LEVEL_UP_LIGHT_DIVIDER',
  INDEX: 'INDEX',
  CARD_GOLD: 'CARD_GOLD',
  MODAL_BACKING_9_SLICE: 'MODAL_BACKING_9_SLICE',
  MODAL_BACKING_9_SLICE_WEB: 'MODAL_BACKING_9_SLICE_WEB',
  BACK_ARROW: 'BACK_ARROW',
  LOYALTY_ICON_WEB: 'LOYALTY_ICON_WEB',
  MOBILE_BACKGROUND_INPUT_FORMS: 'MOBILE_BACKGROUND_INPUT_FORMS',
  TIMER_BACKING: 'TIMER_BACKING',
  WATCH_EARN_POPUP_ICON: 'WATCH_EARN_POPUP_ICON',
  COINS_TEX: 'COINS_TEX',
  HEART_ON: 'HEART_ON',
  GAMES_IMAGE: 'GAMES_IMAGE',
  HEART_OFF: 'HEART_OFF',
  CARD_PLATINUM: 'CARD_PLATINUM',
  SECTION: 'SECTION',
  ROUNDED_RECT_PRIMARY: 'ROUNDED_RECT_PRIMARY',
  SHOP_VIP_DIVIDER_GLOWS: 'SHOP_VIP_DIVIDER_GLOWS',
  SHOP_GOLD_BACKING: 'SHOP_GOLD_BACKING',
  ACCESS_OPTIONS: 'ACCESS_OPTIONS',
  PURCHASE_SUCCEEDED: 'PURCHASE_SUCCEEDED',
  VIP_BACKGROUND: 'VIP_BACKGROUND',
  GLOWING_CONTAINER: 'GLOWING_CONTAINER',
  NAVIGATION_DARKER: 'NAVIGATION_DARKER',
  CARET_DOWN: 'CARET_DOWN',
  CHECKMARK: 'CHECKMARK',
  GENERAL_BACKGROUND: 'GENERAL_BACKGROUND',
  PROMO_IMAGE_COINS: 'PROMO_IMAGE_COINS',
  POPUP_FRAMING: 'POPUP_FRAMING',
  POPUP_FRAMING_MOBILE: 'POPUP_FRAMING_MOBILE',
  FLY_OUT_STAR: 'FLY_OUT_STAR',
  SMART_BANNER_LOGO: 'SMART_BANNER_LOGO',
  GAMES_LOCK: 'GAMES_LOCK',
  LOBBY_COMING_SOON: 'LOBBY_COMING_SOON',
  LOBBY_BACKGROUND: 'LOBBY_BACKGROUND',
  MESSAGE_BACKGROUND: 'MESSAGE_BACKGROUND',
  UNDER_MAINTENANCE: 'UNDER_MAINTENANCE',
  SOCIAL_COMING_SOON: 'SOCIAL_COMING_SOON',
  VIP_ACCESS_STORE_TILE_1_PROMO: 'VIP_ACCESS_STORE_TILE_1_PROMO',
  DIALOG: 'DIALOG',
  VIP_PASS: 'VIP_PASS',
  WELCOME_BONUS: 'WELCOME_BONUS',
  WELCOME_BONUS_WEB: 'WELCOME_BONUS_WEB',
  BADGE_I_OS: 'BADGE_I_OS',
  BADGE_WEB: 'BADGE_WEB',
  BADGE_WEB_APP: 'BADGE_WEB_APP',
  TRACKING_URL_LOGO: 'TRACKING_URL_LOGO',
  GREY_BUTTON_LANDING: 'GREY_BUTTON_LANDING',
  GREEN_BUTTON_LANDING: 'GREEN_BUTTON_LANDING',
  BADGE_ANDROID: 'BADGE_ANDROID',
  MAIL_MESSAGE_DEFAULT: 'MAIL_MESSAGE_DEFAULT',
  LOGO: 'LOGO',
  TIMER_DISPLAYED_SCREEN: 'TIMER_DISPLAYED_SCREEN',
  SNEAK_PEEK_ONBOARDING: 'SNEAK_PEEK_ONBOARDING',
  SNEAK_PEEK_BACKGROUND: 'SNEAK_PEEK_BACKGROUND',
  ICON_SNEAK_PEEK: 'ICON_SNEAK_PEEK',
  PROMOTIONAL_GRAPHIC: 'PROMOTIONAL_GRAPHIC',
  FULL_MODAL_HEADER: 'FULL_MODAL_HEADER',
  FULL_MODAL_HEADER_MOBILE: 'FULL_MODAL_HEADER_MOBILE',
  COINS_TAB_ACTIVE: 'COINS_TAB_ACTIVE',
  UPSELL_TAB_BEST_DEAL: 'UPSELL_TAB_BEST_DEAL',
  ITEM_ROW_PURPLE: 'ITEM_ROW_PURPLE',
  COIN_GLOWING_CONTAINER: 'COIN_GLOWING_CONTAINER',
  COIN_GLOWING_CONTAINER_MOBILE: 'COIN_GLOWING_CONTAINER_MOBILE',
  UPSELL_TAB_POPULAR: 'UPSELL_TAB_POPULAR',
  UPSELL_TAB_AMAZING: 'UPSELL_TAB_AMAZING',
  VIP_TAB_ACTIVE: 'VIP_TAB_ACTIVE',
  ITEM_ROW_GOLD: 'ITEM_ROW_GOLD',
  LOYALTY_ICON_BONUS: 'LOYALTY_ICON_BONUS',
  DEFAULT_BACKGROUND: 'DEFAULT_BACKGROUND',
  VIP_HOUR_TILE_3: 'VIP_HOUR_TILE_3',
  VIP_HOUR_TILE_2: 'VIP_HOUR_TILE_2',
  VIP_HOUR_TILE_1: 'VIP_HOUR_TILE_1',
  VIP_HOUR_TILE_1STAR: 'VIP_HOUR_TILE_1STAR',
  VIP_HOUR_TILE_3STARS: 'VIP_HOUR_TILE_3STARS',
  VIP_HOUR_TILE_2STARS: 'VIP_HOUR_TILE_2STARS',
  VIP_HOUR_TILE_FREE: 'VIP_HOUR_TILE_FREE',
  TOP_BAR_IAP_BACKING: 'TOP_BAR_IAP_BACKING',
  SHOP_VIP_DIVIDER: 'SHOP_VIP_DIVIDER',
  ICON_HIDE: 'ICON_HIDE',
  CLOSE_BUTTON: 'CLOSE_BUTTON',
  ROUNDED_RECT_SECONDARY: 'ROUNDED_RECT_SECONDARY',
  ORANGE_TIMER: 'ORANGE_TIMER',
  VISA_MC: 'VISA_MC',
  SHOP_AMAZING: 'SHOP_AMAZING',
  ROUNDED_RECT_BLUE: 'ROUNDED_RECT_BLUE',
  COIN: 'COIN',
  TOAST_BACKGROUND: 'TOAST_BACKGROUND',
  TILE_BACKER_9SLICE: 'TILE_BACKER_9SLICE',
  DOWNLOAD_ICON: 'DOWNLOAD_ICON',
  ACCEPT_BUTTON: 'ACCEPT_BUTTON',
  INFO_POPUP: 'INFO_POPUP',
  BACK_BUTTON: 'BACK_BUTTON',
  TAB_FRONT: 'TAB_FRONT',
  TAB_BACK: 'TAB_BACK',
  COINS_DEFAULT: 'COINS_DEFAULT',
  CHECKBOX: 'CHECKBOX',
  MODAL_BACKER: 'MODAL_BACKER',
  DAILY_BONUS_PRIZES_BOTTOM_BAR: 'DAILY_BONUS_PRIZES_BOTTOM_BAR',
  TSC_CARDS: 'TSC_CARDS',
  CARD_DIAMOND: 'CARD_DIAMOND',
  OUT_OF_COINS_STATIC_IMAGE: 'OUT_OF_COINS_STATIC_IMAGE',
  MARK: 'MARK',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_CONDITIONS: 'TERMS_CONDITIONS',
  MAIL_ICON_OPEN: 'MAIL_ICON_OPEN',
  LINK_NOW_BUTTON: 'LINK_NOW_BUTTON',
  PROMO_IMAGE_LOYALTY: 'PROMO_IMAGE_LOYALTY',
  RED_TIMER: 'RED_TIMER',
  CARD_XCARD: 'CARD_XCARD',
  LOYALTY_MAIL_ICON: 'LOYALTY_MAIL_ICON',
  CLOCK_ICON_GLOWING: 'CLOCK_ICON_GLOWING',
  SHOP_GOLD_CAP: 'SHOP_GOLD_CAP',
  PINK_BUTTON: 'PINK_BUTTON',
  ROUNDEDRECT_TERTIARY_WIDE: 'ROUNDEDRECT_TERTIARY_WIDE',
  ORANGE_BUTTON: 'ORANGE_BUTTON',
  QUEST20_LOCK: 'QUEST20_LOCK',
  ICON_QUESTION: 'ICON_QUESTION',
  CARD_PREMIER: 'CARD_PREMIER',
  LTO_PROMO_IMAGE: 'LTO_PROMO_IMAGE',
  CHALLENGE_BAR_FILL_END: 'CHALLENGE_BAR_FILL_END',
  BAR_INSET_END: 'BAR_INSET_END',
  CHALLENGE_BAR_FILL: 'CHALLENGE_BAR_FILL',
  EXP_BAR_FILL_END: 'EXP_BAR_FILL_END',
  BAR_INSET: 'BAR_INSET',
  EXP_BAR_FILL: 'EXP_BAR_FILL',
  SIDEBAR_ARROW_BUTTON: 'SIDEBAR_ARROW_BUTTON',
  SIDEBAR_BUTTON: 'SIDEBAR_BUTTON',
  SIDEBAR_BUTTON_HIGHLIGHTED: 'SIDEBAR_BUTTON_HIGHLIGHTED',
  CHECKBOX_DESELECTED: 'CHECKBOX_DESELECTED',
  ROUNDED_RECT_TERTIARY: 'ROUNDED_RECT_TERTIARY',
  CARD_SILVER: 'CARD_SILVER',
  EMPTY_INFO: 'EMPTY_INFO',
  CARD_ELITE: 'CARD_ELITE',
  LINK_BUTTON: 'LINK_BUTTON',
  REWARD_TSC_CARDS: 'REWARD_TSC_CARDS',
  VIP_MESSAGE_ICON: 'VIP_MESSAGE_ICON',
  VIP_MESSAGE_BACKGROUND: 'VIP_MESSAGE_BACKGROUND',
  LIGHT_DIVIDER: 'LIGHT_DIVIDER',
  POINTS_CARDS: 'POINTS_CARDS',
  SCROLL_INDICATOR: 'SCROLL_INDICATOR',
  LOADING_FILL_SIDE: 'LOADING_FILL_SIDE',
  LOADING_BAR_INSET: 'LOADING_BAR_INSET',
  LOADING_LOGO: 'LOADING_LOGO',
  LOADING_FILL_CENTER: 'LOADING_FILL_CENTER',
  LOADING_BAR_BACKING: 'LOADING_BAR_BACKING',
  LOADING_BAR_FILL: 'LOADING_BAR_FILL',
  LOADING_SCREEN: 'LOADING_SCREEN',
  LOADING_LOGO_LEFT: 'LOADING_LOGO_LEFT',
  CLOSE_BUTTON_DANGER: 'CLOSE_BUTTON_DANGER',
  MESSAGE_COINS_BACKGROUND: 'MESSAGE_COINS_BACKGROUND',
  MESSAGE_COINS_ICON: 'MESSAGE_COINS_ICON',
  MESSAGE_LOYALTY_BACKGROUND: 'MESSAGE_LOYALTY_BACKGROUND',
  MESSAGE_LOYALTY_ICON: 'MESSAGE_LOYALTY_ICON',
  MESSAGE_LOYALTY_SUBTITLE_ICON: 'MESSAGE_LOYALTY_SUBTITLE_ICON',
  MESSAGE_SNEAKPEEK_BACKGROUND: 'MESSAGE_SNEAKPEEK_BACKGROUND',
  MESSAGE_SNEAKPEEK_ICON: 'MESSAGE_SNEAKPEEK_ICON',
  MESSAGE_VIP_BACKGROUND: 'MESSAGE_VIP_BACKGROUND',
  MESSAGE_VIP_ICON: 'MESSAGE_VIP_ICON',
  MESSAGE_DEFAULT_BACKGROUND: 'MESSAGE_DEFAULT_BACKGROUND',
  INPUT_EMAIL_ICON_REGULAR: 'INPUT_EMAIL_ICON_REGULAR',
  INPUT_EMAIL_ICON_ERROR: 'INPUT_EMAIL_ICON_ERROR',
  INPUT_PASSWORD_ICON_REGULAR: 'INPUT_PASSWORD_ICON_REGULAR',
  INPUT_PASSWORD_ICON_ERROR: 'INPUT_PASSWORD_ICON_ERROR',
  SESSION_EXPIRED_CLOCK_ICON: 'SESSION_EXPIRED_CLOCK_ICON',
  DIVIDER_ACCESS_RESTRICTED: 'DIVIDER_ACCESS_RESTRICTED',
  CARDS_ACCESS_RESTRICTED: 'CARDS_ACCESS_RESTRICTED',
  EVENTS_ICON_ACTIVE_BLOCK: 'EVENTS_ICON_ACTIVE_BLOCK',
  EVENTS_ICON_ACTIVE_CHECK: 'EVENTS_ICON_ACTIVE_CHECK',
  EVENTS_ICON_ACTIVE_PEN: 'EVENTS_ICON_ACTIVE_PEN',
  EVENTS_ICON_ACTIVE_TEXT: 'EVENTS_ICON_ACTIVE_TEXT',
  MISSIONS_ICON_ARROW: 'MISSIONS_ICON_ARROW',
  MISSIONS_ICON_CIRCLE: 'MISSIONS_ICON_CIRCLE',
  REWARD_CENTER_ICON_GLOW: 'REWARD_CENTER_ICON_GLOW',
  COMING_SOON_TEXT: 'COMING_SOON_TEXT',
  LOBBY_BANNERS_ARROW_BUTTON: 'LOBBY_BANNERS_ARROW_BUTTON',
  LOBBY_BANNERS_SLIDER_MENU: 'LOBBY_BANNERS_SLIDER_MENU',
  LOBBY_BANNERS_YOU_MIGHT_LIKE: 'LOBBY_BANNERS_YOU_MIGHT_LIKE',
  LOBBY_BANNERS_NOW_TRENDING: 'LOBBY_BANNERS_NOW_TRENDING',
  BOTTOM_BAR_BACKING_SLIM: 'BOTTOM_BAR_BACKING_SLIM',
  BOTTOM_BAR_PLUS_BUTTON: 'BOTTOM_BAR_PLUS_BUTTON',
  BOTTOM_BAR_ARROW_BUTTON: 'BOTTOM_BAR_ARROW_BUTTON',
  BOTTOM_BAR_MENU_SLIM: 'BOTTOM_BAR_MENU_SLIM',
  EMAIL_CONFIRMATION_BAKCGROUND: 'EMAIL_CONFIRMATION_BAKCGROUND',
  EMAIL_CONFIRMATION_POPUP: 'EMAIL_CONFIRMATION_POPUP',
  SOUND_ON: 'SOUND_ON',
  SOUND_OFF: 'SOUND_OFF',
  PLAY_ON_ICON: 'PLAY_ON_ICON',
  ROTATE_SCREEN_BACKGROUND: 'ROTATE_SCREEN_BACKGROUND',
  ROTATE_SCREEN_ROTATE_ICON: 'ROTATE_SCREEN_ROTATE_ICON',
  ROTATE_SCREEN_UNLOCK_ICON: 'ROTATE_SCREEN_UNLOCK_ICON',
  BROWSER_MOBILE_SCREEN_BACKGROUND: 'BROWSER_MOBILE_SCREEN_BACKGROUND',
  BROWSER_MOBILE_SCREEN_SHARED_ICON: 'BROWSER_MOBILE_SCREEN_SHARED_ICON',
  BROWSER_MOBILE_SCREEN_ADD_ICON: 'BROWSER_MOBILE_SCREEN_ADD_ICON'
};