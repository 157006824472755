import theme from 'shared/assets/style/theme';
import defaultTheme from './defaultTheme';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { isIOS, isMobile, isTablet } from 'react-device-detect';
import { isNotStandaloneMobilePWA, isStandalonePWA, pwaStyleAdapter } from '../utils/mobileSupported';

const windowWidth = window.innerWidth;

const HriTheme = {
  ...defaultTheme,
  AssetPreloader: {
    ...defaultTheme.AssetPreloader,
    wideLoadingBarcontainer: {
      ...defaultTheme.AssetPreloader.wideLoadingBarcontainer,
      top: isNotStandaloneMobilePWA ? '45%' : '38%',
      transform: isNotStandaloneMobilePWA ? 'translateY(-50%)' : 'none',
    },
  },
  LimitedTimeOffer: {
    ...defaultTheme.LimitedTimeOffer,
    BannerContainer: {
      ...defaultTheme.LimitedTimeOffer.BannerContainer,
      marginBottom: 10,
    },
  },
  DailySpin: {
    ...defaultTheme.DailySpin,
    MileStoneRowLoyaltyIcon: {
      ...defaultTheme.DailySpin.MileStoneRowLoyaltyIcon,
      marginBottom: isMobile ? '1rem' : 0,
    },
  },
  NavigationBar: {
    ...defaultTheme.NavigationBar,
    BarSection: {
      ...defaultTheme.NavigationBar.BarSection,
      paddingBottom: 10,
    },
    LoyaltyCurrencyWrapperStyle: {
      ...defaultTheme.NavigationBar.LoyaltyCurrencyWrapperStyle,
      position: 'absolute',
      right:
        isMobile && !isTablet ? 'calc(50% - 235px)' : windowWidth < 1300 ? 'calc(50% - 200px)' : 'calc(50% - 205px)',
    },
  },
  Tier: {
    ...defaultTheme.Tier,
    Card: {
      ...defaultTheme.Tier.Card,
      width: 75,
      ...(!isIOS && { top: -1 }),
    },
  },
  TimeRemaining: {
    ...defaultTheme.TimeRemaining,
    TimerLabel: {
      ...defaultTheme.TimeRemaining.TimerLabel,
      top: '-0.5rem',
    },
    VIPTimer: {
      ...defaultTheme.TimeRemaining.VIPTimer,
      top: pwaStyleAdapter(4.3, 'rem'),
    },
  },
  BonusLinkMessage: {
    ...defaultTheme.BonusLinkMessage,
    PromoImage: {
      ...defaultTheme.BonusLinkMessage.PromoImage,
      paddingTop: '376px',
    },
    BonusLinkButton: {
      ...defaultTheme.BonusLinkMessage.BonusLinkButton,
      textShadow: `1px 1px 0px ${theme.palette.secondary[0]}, 1px -1px 0px ${theme.palette.secondary[0]}, -1px 1px 0px ${theme.palette.secondary[0]}, -1px -1px 0px ${theme.palette.secondary[0]}`,
    },
  },
  OfferDetail: {
    ...defaultTheme.OfferDetail,
    PurchaseResponseHighlightHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHighlightHeader,
      fontWeight: '600',
    },
    PurchaseResponseHeader: {
      ...defaultTheme.OfferDetail.PurchaseResponseHeader,
      fontWeight: '600',
    },
    RewardCenterPurchaseResponseMessage: {
      ...defaultTheme.OfferDetail.RewardCenterPurchaseResponseMessage,
      fontSize: '1em',
    },
    ExclamationIcon: {
      ...defaultTheme.OfferDetail.ExclamationIcon,
      width: 126,
      height: 'auto',
    },
  },
  LinkAccountForm: {
    ...defaultTheme.LinkAccountForm,
    ScreenBackground: {
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
    HeaderTitle: {
      ...defaultTheme.LinkAccountForm.HeaderTitle,
      margin: 4,
    },
  },
  VIPItem: {
    ...defaultTheme.VIPItem,
    VIPHourTile: {
      ...defaultTheme.VIPItem.VIPHourTile,
      width: windowWidth < 1300 ? '35%' : '32%',
    },
    LogoImage: {
      height: !isMobile ? (windowWidth < 1300 ? '40%' : '32%') : isTablet ? '28%' : '35%',
      marginTop: !isMobile ? 0 : isTablet || isStandalonePWA ? '1rem' : '2rem',
    },
    ItemPlusOffer: {
      ...defaultTheme.VIPItem.ItemPlusOffer,
      paddingTop: '0.5rem',
    },
  },
  Settings: {
    ...defaultTheme.Settings,
    BackgroundLightDivider: {
      ...defaultTheme.Settings.BackgroundLightDivider,
      backgroundImage: `url(${assetSource(ASSET_KEY.LIGHT_DIVIDER)})`,
      backgroundSize: '80%',
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: 'center',
      backgroundPositionY: 31,
    },
  },
  Wheel: {
    ...defaultTheme.Wheel,
    Amount: {
      ...defaultTheme.Wheel.Amount,
      color: theme.palette.common[1],
      textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    },
  },
  BottomBar: {
    ...defaultTheme.BottomBar,
    Divider: {
      ...defaultTheme.BottomBar.Divider,
      bottom: -10,
    },
    TimerCollectBackground: {
      ...defaultTheme.BottomBar.TimerCollectBackground,
      bottom: '0.1rem',
    },
  },
  StoreDownload: {
    ...defaultTheme.StoreDownload,
    Container: {
      ...defaultTheme.StoreDownload.Container,
      backgroundColor: 'black',
    },
  },
  RewardCenter: {
    ...defaultTheme.RewardCenter,
    RewardCenterMainContentHolder: {
      ...defaultTheme.RewardCenter.RewardCenterMainContentHolder,
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  },
  CreditCardDetails: {
    ...defaultTheme.CreditCardDetails,
    CheckBoxBackgroundImage: {
      ...defaultTheme.CreditCardDetails.CheckBoxBackgroundImage,
      width: '40%',
    },
  },
  Start: {
    ...defaultTheme.Start,
    Logo: {
      ...defaultTheme.Start.Logo,
      marginTop: window.innerHeight > 700 ? '6%' : '0px',
    },
    MainContentContainer: {
      ...defaultTheme.Start.MainContentContainer,
      marginTop: window.innerHeight > 700 ? 30 : -20,
    },
    MiddleLayoutButtonContainer: {
      ...defaultTheme.Start.MiddleLayoutButtonContainer,
      marginRight: '2rem',
    },
    BottomBarFooterText: {
      ...defaultTheme.Start.BottomBarFooterText,
      marginTop: 10,
      textShadow: '2px 2px 2px #000000',
    },
    MiddleSectionWrapper: {
      ...defaultTheme.Start.MiddleSectionWrapper,
      paddingInlineStart: '0rem',
    },
  },
  ShopScreen: {
    ...defaultTheme.ShopScreen,
    ShopContainer: {
      ...defaultTheme.ShopScreen.ShopContainer,
      PackagesContainer: {
        ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer,
        padding: '6% 5.5rem 0.4rem',
        CoinItem: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.CoinItem,
          left: '2px',
        },
        VIPItemContainer: {
          ...defaultTheme.ShopScreen.ShopContainer.PackagesContainer.VIPItemContainer,
          ...(!isMobile && { marginLeft: 0 }),
        },
      },
    },
  },
  MissionsProgress: {
    ...defaultTheme.MissionsProgress,
    MissionIcon: {
      ...defaultTheme.MissionsProgress.MissionIcon,
      top: -10,
    },
  },
  MissionCard: {
    ...defaultTheme.MissionCard,
    TimerTextWithIcon: {
      ...defaultTheme.MissionCard.TimerTextWithIcon,
      bottom: '0.2rem',
    },
  },
  Bar: {
    ...defaultTheme.Bar,
    BarContainer: {
      ...defaultTheme.Bar.BarContainer,
      background: 'linear-gradient(180deg, rgba(33, 30, 28, 1) 0%, rgba(158, 136, 49, 1) 100%)',
    },
  },
  BrowserScreen: {
    ...defaultTheme.BrowserScreen,
    DescriptionWrap: {
      ...defaultTheme.BrowserScreen.DescriptionWrap,
      marginTop: 0,
    },
    ButtonContainer: {
      ...defaultTheme.BrowserScreen.ButtonContainer,
      marginTop: '5vh',
    },
  },
};

export default HriTheme;

